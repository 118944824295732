import(/* webpackMode: "eager" */ "/codebuild/output/src409223378/src/ol-backoffice3-template/node_modules/antd/dist/reset.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src409223378/src/ol-backoffice3-template/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src409223378/src/ol-backoffice3-template/src/app/_components/app-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src409223378/src/ol-backoffice3-template/src/app/_components/config-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src409223378/src/ol-backoffice3-template/src/app/_components/style-provider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src409223378/src/ol-backoffice3-template/src/styles/common.css");
