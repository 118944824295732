"use client";

import {AntdRegistry} from "@ant-design/nextjs-registry";
import React from "react";

// Using Next.js App Router
// https://ant.design/docs/react/use-with-next
const StyleProvider = ({children}: {children: React.ReactNode}) => {
  return <AntdRegistry>{children}</AntdRegistry>;
};

export default StyleProvider;
