"use client";

import {AppleFilled, GoogleCircleFilled} from "@ant-design/icons";
import {Button, Form, Input, Typography} from "antd";
import Image from "next/image";
import {signIn} from "next-auth/react";
import {useTranslations} from "next-intl";
import React, {MouseEvent, useState} from "react";
import {createUseStyles} from "react-jss";

import Logo from "../../assets/logo.png";
import {MicrosoftIcon} from "@/assets/icons/Microsoft";
import {AuthenticationProvider} from "@/shared/modules/authentication/authentication-provider";

const width = "300px";

const useStyles = createUseStyles({
  container: {
    marginTop: "10vh",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  loginContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width,
  },
  input: {
    margin: 5,
  },
  inputContainer: {
    width: "100%",
  },
  button: {
    margin: "5px",
    alignItems: "center",
    width,
  },
  buttonIcon: {
    height: 20,
    width: 20,
  },
  buttonText: {
    marginLeft: 10,
  },
  text: {
    marginTop: "1.5rem",
    marginBottom: "0.5rem",
  },
  logo: {
    width: "75%",
    objectFit: "contain",
    height: "auto",
  },
  footerSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2rem",
    paddingTop: "2rem",
    borderTop: "1px solid rgba(150,150,150,0.3)",
    width,
  },
  logoBottom: {
    width: "6vw",
    objectFit: "contain",
    height: "auto",
    filter: "grayscale()",
    marginBottom: "15px",
  },
});

const SignInForm = () => {
  const translation = useTranslations("authentication");
  const styles = useStyles();

  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleAppleSignIn = (e: MouseEvent) => {
    e.preventDefault();
    signIn(AuthenticationProvider.Apple);
  };

  const handleAzureSignIn = (e: MouseEvent) => {
    e.preventDefault();
    signIn(AuthenticationProvider.Azure);
  };

  const handleGoogleSignIn = (e: MouseEvent) => {
    e.preventDefault();
    signIn(AuthenticationProvider.Google);
  };

  const handleAuth0SignIn = (e: MouseEvent) => {
    e.preventDefault();
    signIn(AuthenticationProvider.Auth0);
  };

  const handleCredentialsSignIn = async () => {
    try {
      setLoading(true);
      await signIn(AuthenticationProvider.Credentials, {email, password});
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <Form onFinish={handleCredentialsSignIn}>
        <div className={styles.loginContainer}>
          <Image className={styles.logo} src={Logo} alt="company logo" />
          <Typography.Text className={styles.text}>{translation("signInTitle")}</Typography.Text>
          <Form.Item className={styles.inputContainer}>
            <Input
              disabled={loading}
              className={styles.input}
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              placeholder={translation("signInEmailPlaceholder")}
            />
          </Form.Item>
          <Form.Item className={styles.inputContainer}>
            <Input
              disabled={loading}
              className={styles.input}
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              placeholder={translation("signInPasswordPlaceholder")}
            />
          </Form.Item>
          <Form.Item className={styles.inputContainer}>
            <Button
              loading={loading}
              htmlType="submit"
              className={styles.button}
              type="primary"
              size="large"
              onSubmit={handleCredentialsSignIn}>
              {translation("signInButton")}
            </Button>
          </Form.Item>
        </div>
      </Form>
      <Typography.Text className={styles.text}>{translation("signInOtherOptions")}</Typography.Text>
      <Button
        className={styles.button}
        size="large"
        icon={<GoogleCircleFilled className={styles.buttonIcon} />}
        onClick={handleGoogleSignIn}>
        <span className={styles.buttonText}>Google</span>
      </Button>
      <Button
        className={styles.button}
        size="large"
        icon={<MicrosoftIcon className={styles.buttonIcon} />}
        onClick={handleAzureSignIn}>
        <span className={styles.buttonText}>Microsoft</span>
      </Button>
      <Button
        className={styles.button}
        size="large"
        icon={<AppleFilled className={styles.buttonIcon} />}
        onClick={handleAppleSignIn}>
        <span className={styles.buttonText}>Apple</span>
      </Button>
      <Button
        className={styles.button}
        size="large"
        icon={<AppleFilled className={styles.buttonIcon} />}
        onClick={handleAuth0SignIn}>
        <span className={styles.buttonText}>Auth0</span>
      </Button>
      <section className={styles.footerSection}>
        <Image className={styles.logoBottom} src={Logo} alt="company logo" />
        <Typography.Text> {translation("signInFooter")}</Typography.Text>
        <span>
          <Typography.Link href="https://orangeloops.com/about.html">{translation("signInAboutUs")}</Typography.Link> -
          <Typography.Link href="https://orangeloops.com/services.html">
            {" "}
            {translation("signInServices")}
          </Typography.Link>
        </span>
      </section>
    </div>
  );
};

export default SignInForm;
